body{
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.App {
  padding: 0; 
  margin: 0;
  position: fixed;
  left:0;
  top:0;
  background-image: url("./img/podcast-feathered-B.jpg");
  background-color: rgb(153, 153, 153);
  background-size: cover;
  background-position: fixed;
  background-position-x: -185px;
  background-position-y: -95px;
  background-repeat: no-repeat;
  box-shadow: 4px 4px 5px black;
}

@keyframes feedBlink {
  0% { 
    opacity: 0.5; 
    transform: scale(0.9); 
  }
  100% {
      opacity: 0.7; 
      transform: scale(0.8); 
    }
}
  .feed-banner{
    position: fixed;
    display: flex;
    cursor: copy;
    border-radius: 5%;
    border: 1px dashed white;
    opacity: 0.7;
    top: 0;
    padding: 0;
    margin: 0;
    height: 1rem;
    background-color: rgba(255,255,255,0.5);
    /* background-color: rgba(255,255,255,0.5);
    background-image: radial-gradient(rgba(255, 145, 0, 0.822), rgb(255, 255, 255)); */
    font-size: .65rem;
    box-shadow: 5px 2px 3px 3px rgba(255, 255, 255, 0.055);
    transition: all 1s ease;
    animation: feedBlink 4.5s ease-in-out 5 alternate-reverse;
  }
  .feed-banner pre {
    margin-left: 1rem;
    padding: 0;
    margin-top: 0;
    position: relative;
    top: 0.3rem;
    height: .3rem;
    width: inherit;
    box-shadow: 2px 2px 5px white;
  }
  .feed-banner:hover {
    border-radius: 15px;
    background-color: rgba(239, 255, 15, 0.609);
    opacity: 1;
    border: 1px dashed black;
    animation: none;
    transform: scale(0.85);
  }

a, a:active, a:visited {
  color: white;
  text-decoration: none;
}
/* VIDEO IN LINKS MODAL */
.vid-style {
  width: 75vw;
  object-fit: cover;
  border: 4px outset black;
  border-radius: 8%;
}

/* SOME MODAL STYLES IN COMPONENT FILE */
/* MODAL PAGE STYLES BELOW */
.close-btn {
  color: white;
  background-color: rgba(200, 200, 200, 0.05);
  position: absolute;
  margin: 2px;
  margin-right: 15px;
  padding: 1px;
  right: 0;
  top: 0;
  font-size: 1.05rem;
  border: 2px inset white;
  border-radius: 0 0 0 20%;
  box-shadow: 0 0 4px white;
  transition: all 0.2s linear;
}
.close-btn:hover{
  color: black;
  margin-right: 17px;
  background-color: rgba(233, 233, 233, 1);
}
.close-btn:hover .close-btn-text{
  font-size: 0.95rem;
}
.close-btn-text{
  font-size: 0.7rem;
  transition: font 0.15s linear;
}
.modal-text-content {
  text-align: justify;
  margin: 3vw;
  margin-left: 2vw;
  margin-right: 20px;
  text-shadow: 2px 2px black;
}
.mp-header {
  object-fit: cover;
  width: 90%;
  border-radius: 15px;
  margin-top: -2vh;
  margin-left: 2vw;
}
.mp-head-text {
  position: relative;
  margin-top: -15vh;
  margin-left: 2vw;
  margin-right: 5vh;
  text-align: center;
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.65);
}
.album-header{
  object-fit: cover;
  width: 80%;
  margin-top: 10vh;
}
.author-header {
  object-fit: cover;
  width: 60%;
  margin-top: 5vh;
}

.album-head-text {
  text-align: center;
  position: absolute;
  top: -1vw;
}
.auth-text-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.author-text{
  position: relative;
  top: 5vh;
}
.author-head-text {
  padding: 10px;
  text-align: center;
  margin-top: 4vh;
  background-color: black;
}
.bio-portrait{
  position: relative;
  object-fit: cover;
  width: 80%;
}
/* .tracks {}  not used but it's there */
.graphics-modal{
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}
.cg-header{
  object-fit: cover;
  width: 70%;
}
.cg-banner{
  text-align: center;
  position: relative;
  object-fit: cover;
  width: 40%;
}
.research-header{
  object-fit: contain;
  width: 40%;
}
.modal-text-links {
  margin: 15px;
}
/* MODAL PAGE CONTENT STYLES ABOVE */

/* MAIN PAGE STYLE ANIMATIONS */
@keyframes loaderAnimation {
  0% {opacity: 1; }
  50% {opacity: 0.25; }
  100% {opacity: 1; }
}
.loaderSpinner {
  position: relative;
  display: inline-block;
  width: 15%;
  height: 15%;

  animation-name: loaderAnimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}
.spinner-div{
  position: absolute;
  margin-top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.spinner-div-height {
  margin-top: 35vh;
  animation-name: loaderAnimation;
  animation-duration: 1.9s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
}

/* MAIN APP STYLES */
.App-header {
  height: 100vh;
  width: 100vw;
  padding: 15px;
  margin: 10px;
  /* border: 1px solid black; */
}
.div-fade {
  /* visibility: hidden; */
  height: 7vw;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    white,
    rgba(0, 0, 0, 0.85),
    white,
    #676767,
    #676767,
    white,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.div-fade-vert {
  visibility: hidden;
  background-image: linear-gradient( 
    to left,
    rgba(255, 255, 255, 0),
    white,
    rgba(0, 0, 0, 0.85),
    rgba(255, 255, 255, 0.404),
    #676767,
    #676767,
    white,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  margin: -50px;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  width: 7vw;
  height: 100vw;
  z-index: 101;
}

.thumb-view-wrapper {
  position: absolute;
  /* bottom: 25px; */
  bottom: 10vh;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 7fr 1fr;
  grid-gap: 5px;
  margin: 0;
  padding: 0;
  /* height: 56vh; */
  height: 56vh;
  width: 93vw;
  background-color: rgba(0, 0, 0, 0.45);
  box-shadow: -3px -3px 3px black;
}
.thumb-view-container {
  position: relative;
  opacity: 0;
  background-image: radial-gradient(black, rgba(255,255,255,0));
  box-shadow: -2px -2px 2px rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  padding: 0px;
  border-left: 2px inset gold;
  border-bottom: 4px inset rgba(255, 217, 0, 0.459);
}

/* rotate image effect */
.img-card-container {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  perspective: 600;
  z-index: 199;
}
.img-card-container:hover .img-card {
  opacity: 1;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 1), 15px 15px 10px yellow;
  transform: scale(1.23) rotateX(22deg) rotateY(13deg);
}

.img-card-container:hover .textDirection>p {
  letter-spacing: 0.7em;
  color: yellow;
  font-weight: bolder;
  text-shadow: 2px 2px 3px rgb(0, 0, 0);
  margin-top: -25px;
}

/* NAV CARD TEXT  */
.textDirection>p {
  position: absolute;
  top: -5px;
  left: 8px;
  color: white;
  letter-spacing: 0.15em;
  line-height: 0.04rem;
  font-size: 0.75rem;
  font-weight: bolder;
  text-shadow: 2px 2px black;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 20px;
  margin-left: -15px;
  margin-top: -10px;
  z-index: 2000;
  transition: all 0.45s ease-in-out;
}


/* FOOTER STYLES */
.footer-div {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.705);
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 20px;
  z-index: 1000;
}

.footer-div>p {
  font-size: .65rem;
  margin: 2px;
  padding: 2px;
}


/* INTRO ANIMATIONS */
@keyframes slideFromLeft {
  0% { margin-top: -500px; opacity: 0 }
  70% { margin-top: 10px; opacity: 1 }
  100% { margin-top: 0px; opacity: 1 }
}

/* topmost shine effect */
.scanimation2 {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.35),
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0.45;
  transform: scale(0.87);
  background-position: 150% 150%;
  transition: all 0.5s cubic-bezier(0.26, 0.24, 0.31, 1.48) 0s;
  z-index: 201;
}
.img-card-container:hover .scanimation2 {
  transform: scale(1.13) rotateX(27deg) rotateY(13deg);
  background-size: 250%;
  background-position: -150% -150%;
  z-index: 201;
}


/* bottom most shine effect */
/* .scanimation {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.45),
    rgba(255, 255, 255, 0)
  );
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-position: -100%;
  transform: scale(0.87);
  transition: all 0.5s cubic-bezier(0.26, 0.24, 0.31, 1.48) 0s;
  z-index: 200;
  box-shadow: 2px 2px 4px black;
} */

/* .img-card-container:hover .scanimation {
  transform: scale(1.23) rotateX(27deg) rotateY(13deg);
  background-size: 450%;
  background-position: 150% 150%;
  z-index: 200;
  box-shadow: 4px 4px 12px black;
  transform: rotateX(7deg) rotateY(7deg) rotate(5deg) scale(1.13);
} */


